import { getRequest, postRequest } from '~/infra/api/request'

const useInviteStore = defineStore('useInvite', {
  state: () => ({
    listInviteCollapse: [],
    listInvite: [],
    listPartnerOfPartner: [],
    yourPartners: {},
    nextCursor: '',
    isAllowOpenList: false,
    partnerNextCursor: '',
    dataCommission: {
      direct_referral: 0,
      direct_referral_paid: 0,
      indirect_referral: 0,
      indirect_referral_paid: 0,
      total_commission: '',
      total_commission_claimed: '',
      total_commission_unclaimed: '',
      total_commission_unclaimed_val: '',
      pending_commission: '',
      pending_commission_val: 0,
      expired_commission: '',
      expired_at: ''
    },
    totalPage: 0,
    commissonPartners: [],
    commissonTransactions: [],
    configClaim: {
      fee: 0,
      min_withdraw: 5,
      max_withdraw: 10000
    }
  }),
  actions: {
    async getListInvite(params = { limit: 100, order_by: '', order: '', is_first: false, is_init: false, is_query: false }) {
      if (!this.nextCursor && !params.is_first) {
        return
      }
      try {
        const resp = await getRequest('/invite/partners', { ...params, cursor: this.nextCursor })
        if (resp.data.status_code === 200) {
          if (params?.is_first) {
            this.isAllowOpenList = !resp.data.data?.previous_cursor && resp.data.data.data.length > 0
          }

          if (params?.is_init) {
            this.listInvite = resp?.data?.data?.data
            this.listInviteCollapse = resp?.data?.data?.data
          }

          if (this.nextCursor && resp.data.data.nextCursor !== this.nextCursor) {
            this.listInviteCollapse = [...this.listInviteCollapse, ...resp.data?.data?.data]
          }

          if (params.is_query) {
            this.listInviteCollapse = resp?.data?.data?.data
          }

          this.nextCursor = resp.data?.data?.next_cursor
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getDataUser() {
      try {
        const resp = await getRequest('/invite')
        this.yourPartners = resp.data.data
      } catch (e) {
        console.log(e)
      }
    },
    async getDataCommission() {
      try {
        const res = await getRequest('/commission')
        this.dataCommission = res.data.data
      } catch (e) {
        console.log(e)
      }
    },
    async getCommissionPartner(limit = 100, orderBy = '', order = '', keyword = '') {
      if (this.nextCursor === null) {
        return
      }
      try {
        const resp = await getRequest(`/commission/partners?limit=${limit}&order_by=${orderBy}&order=${order}&keyword=${keyword}&cursor=${this.nextCursor}`)
        if (resp.data.status_code === 200) {
          this.nextCursor = resp.data?.data.next_cursor
          this.commissonPartners = resp.data.data.data.length ? [...this.commissonPartners, ...resp.data.data.data] : this.commissonPartners
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getCommissionTransactions(limit = 100, orderBy = '', order = '', keyword = '') {
      if (this.nextCursor === null) {
        return
      }
      try {
        const resp = await getRequest(`/commission/transactions?limit=${limit}&order_by=${orderBy}&order=${order}&keyword=${keyword}&cursor=${this.nextCursor}`)
        if (resp.data.status_code === 200) {
          this.nextCursor = resp.data?.data?.next_cursor
          this.commissonTransactions = resp.data.data.data.length ? [...this.commissonTransactions, ...resp.data.data.data] : this.commissonTransactions
        }
      } catch (e) {
        console.log(e)
      }
    },
    async commissionClaim() {
      let success
      try {
        const res = await postRequest('/commission/claim')
        if (res.data.status_code === 200) {
          success = true
        }else{
          success = false
          ElMessage({
            message: res.data.message,
            type: 'error',
            grouping: true
          })
        }
      } catch (e) {
        success = false
        console.log(e)
      }
    },
    async getListPartnerOfPartner(limit = 100, partnerId, orderBy = '', order = '') {
      if (this.partnerNextCursor === null) {
        return
      }
      try {
        const resp = await getRequest(
          `/invite/partners?limit=${limit}&partner_id=${partnerId}&order_by=${orderBy}&order=${order}&cursor=${this.partnerNextCursor}`
        )
        if (this.partnerNextCursor !== resp.data?.data?.next_cursor) {
          this.listPartnerOfPartner = [...this.listPartnerOfPartner, ...resp.data?.data?.data]
        }

        this.partnerNextCursor = resp.data?.data?.next_cursor
      } catch (e) {
        console.log(e)
      }
    },

    async inviteReferrer(payload) {
      try {
        const resp = await postRequest('/invite/register-ref', payload)
        if (resp && resp.data.status_code === 200) {
          await this.getDataUser()
        } else {
          ElMessage.warning({ message: resp?.data?.message })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async dataConfigWithdraw(){
      try{
        const resp = await getRequest('/commission/config')
        this.configClaim = resp.data.data
      }catch(e){
        console.log(e)
      }
    },
    // async withdrawCommission(data) {
    //   try {
    //     const resp = await postRequest('/commission/withdraw', data)
    //     if (resp.data.status_code === 200) {
    //       ElMessage({
    //         message: 'Your commission has been successfully withdrawn to your wallet.',
    //         type: 'success',
    //         grouping: true,
    //         duration: 3000
    //       })
    //     }
    //     if (resp.data.status_code === 400) {
    //       ElMessage({
    //         message: "Withdrawal failed: Commission hasn't reached the minimum required amount.",
    //         type: 'error',
    //         grouping: true,
    //         duration: 3000
    //       })
    //     }
    //   } catch (e) {
    //     console.log(e)
    //   }
    // }
  }
})
export default useInviteStore
